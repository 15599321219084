.rw-btn,
.rw-input-reset,
.rw-input,
.rw-dropdown-list-autofill,
.rw-filter-input {
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
  background-image: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation;
}

.rw-btn::-moz-focus-inner {
  padding: 0;
  border: 0;
}

select.rw-input {
  text-transform: none;
}

html input[type="button"].rw-input {
  -webkit-appearance: button;
  cursor: pointer;
}

textarea.rw-input {
  overflow: auto;
  resize: vertical;
}

button[disabled].rw-input,
fieldset[disabled] .rw-input,
html input[disabled].rw-input {
  cursor: not-allowed;
}

button.rw-input::-moz-focus-inner,
input.rw-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

@font-face {
  font-family: 'RwWidgets';
  font-weight: normal;
  font-style: normal;
  src: url(/static/media/rw-widgets.bc7c4a59.eot);
  src: url(/static/media/rw-widgets.bc7c4a59.eot?#iefix&v=4.1.0) format('embedded-opentype'),url(/static/media/rw-widgets.12f0820c.woff) format('woff'),url(/static/media/rw-widgets.eceddf47.ttf) format('truetype'),url(/static/media/rw-widgets.792dcd18.svg#fontawesomeregular) format('svg');
}

.rw-i {
  display: inline-block;
  color: inherit;
  font-family: RwWidgets;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.rw-i-caret-down:before {
  content: '\E803';
}

.rw-i-caret-up:before {
  content: '\E800';
}

.rw-i-chevron-left:before {
  content: '\F104';
}

.rw-i-chevron-right:before {
  content: '\F105';
}

.rw-i-clock-o:before {
  content: '\E805';
}

.rw-i-calendar:before {
  content: '\E804';
}

.rw-i-search:before {
  content: '\E801';
}

.rw-btn {
  position: relative;
  color: #333;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
}

.rw-state-readonly .rw-btn,
.rw-state-disabled .rw-btn {
  cursor: not-allowed;
}

.rw-btn-select {
  opacity: .75;
  -webkit-transition: opacity 150ms ease-in;
  transition: opacity 150ms ease-in;
}

.rw-btn-select:hover,
.rw-state-focus .rw-btn-select,
:hover>.rw-btn-select {
  opacity: 1;
}

.rw-btn-primary {
  width: 100%;
  white-space: normal;
  line-height: 2em;
}

.rw-btn-primary:hover {
  background-color: #e6e6e6;
}

.rw-btn-select[disabled],
.rw-btn-primary[disabled],
fieldset[disabled] .rw-btn-select,
fieldset[disabled] .rw-btn-primary {
  box-shadow: none;
  cursor: not-allowed;
  opacity: .65;
  pointer-events: none;
}

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.rw-widget {
  background-clip: border-box;
  border: none;
  color: #333;
  font-size: 1em;
  font-family: inherit;
  outline: none;
  position: relative;
}

.rw-widget,
.rw-widget * {
  box-sizing: border-box;
}

.rw-widget:before,
.rw-widget *:before,
.rw-widget:after,
.rw-widget *:after {
  box-sizing: border-box;
}

.rw-widget>.rw-widget-container {
  width: 100%;
  margin: 0;
}

.rw-widget-container {
  background-color: #fff;
  border: #ccc 1px solid;
  border-radius: 4px;
}

.rw-widget-container.rw-state-focus,
.rw-state-focus>.rw-widget-container,
.rw-widget-container.rw-state-focus:hover,
.rw-state-focus>.rw-widget-container:hover {
  background-color: #fff;
  border-color: #66afe9;
  box-shadow: 0 0 8px rgba(102,175,233,0.6);
}

.rw-widget-container.rw-state-readonly,
.rw-state-readonly>.rw-widget-container {
  cursor: not-allowed;
}

.rw-widget-container.rw-state-disabled,
.rw-state-disabled>.rw-widget-container,
fieldset[disabled] .rw-widget-container,
.rw-widget-container.rw-state-disabled:hover,
.rw-state-disabled>.rw-widget-container:hover,
fieldset[disabled] .rw-widget-container:hover,
.rw-widget-container.rw-state-disabled:active,
.rw-state-disabled>.rw-widget-container:active,
fieldset[disabled] .rw-widget-container:active {
  box-shadow: none;
  cursor: not-allowed;
}

.rw-widget-picker {
  position: relative;
  overflow: hidden;
  border-collapse: separate;
  display: inline-table;
  height: 2.429em;
}

.rw-widget-picker>* {
  position: relative;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  display: table-cell;
}

.rw-widget-picker>.rw-select {
  width: 1%;
  white-space: nowrap;
}

.rw-open>.rw-widget-picker {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rw-open-up>.rw-widget-picker {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

fieldset[disabled] .rw-widget-picker,
.rw-state-disabled>.rw-widget-picker {
  background-color: #eee;
}

.rw-multiselect>.rw-widget-picker {
  height: auto;
}

.rw-select {
  cursor: pointer;
}

.rw-select>* {
  width: 1.9em;
  height: 100%;
}

.rw-state-readonly .rw-select,
.rw-state-disabled .rw-select {
  cursor: not-allowed;
}

.rw-select-bordered {
  cursor: pointer;
  border: none;
  border-left: #ccc 1px solid;
}

.rw-select-bordered:hover,
.rw-select-bordered:active {
  background-color: #e6e6e6;
}

.rw-select-bordered:active {
  box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
}

.rw-state-disabled .rw-select-bordered,
.rw-state-readonly .rw-select-bordered,
fieldset[disabled] .rw-select-bordered,
.rw-state-disabled .rw-select-bordered:hover,
.rw-state-readonly .rw-select-bordered:hover,
fieldset[disabled] .rw-select-bordered:hover,
.rw-state-disabled .rw-select-bordered:active,
.rw-state-readonly .rw-select-bordered:active,
fieldset[disabled] .rw-select-bordered:active {
  cursor: not-allowed;
  background-color: inherit;
  background-image: none;
  box-shadow: none;
}

.rw-rtl .rw-select-bordered {
  border-right: #ccc 1px solid;
  border-left: none;
}

.rw-rtl {
  direction: rtl;
}

.rw-input-reset,
.rw-input,
.rw-dropdown-list-autofill,
.rw-filter-input {
  outline: 0;
}

.rw-input-reset::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.rw-input-reset:-ms-input-placeholder {
  color: #999;
}

.rw-input-reset::-webkit-input-placeholder {
  color: #999;
}

.rw-input,
.rw-dropdown-list-autofill,
.rw-filter-input {
  color: #555;
  padding: 0 .857em;
  background-color: #fff;
}

.rw-input[type="text"]::-ms-clear {
  display: none;
}

.rw-input[disabled],
fieldset[disabled] .rw-input {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  background-color: #eee;
  border-color: #ccc;
}

.rw-input[readonly] {
  cursor: not-allowed;
}

.rw-i.rw-loading {
  display: block;
  background: url(data:image/gif;base64,R0lGODlhEAAQAPIAAP///zMzM87OzmdnZzMzM4GBgZqamqenpyH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==) no-repeat center;
  min-width: 16px;
  width: 1.9em;
  height: 16px;
}

.rw-i.rw-loading:before {
  content: "";
}

.rw-placeholder {
  color: #999;
}

.rw-detect-autofill:-webkit-autofill {
  -webkit-animation-name: react-widgets-autofill-start;
          animation-name: react-widgets-autofill-start;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s;
}

.rw-detect-autofill:not(:-webkit-autofill) {
  -webkit-animation-name: react-widgets-autofill-cancel;
          animation-name: react-widgets-autofill-cancel;
}

.rw-webkit-autofill .rw-widget-container,
.rw-input:-webkit-autofill {
  background-color: #faffbd !important;
  background-image: none !important;
  color: #000 !important;
}

.rw-widget-input,
.rw-filter-input {
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}

.rw-widget-input.rw-state-focus {
  box-shadow: 0 0 8px rgba(102, 175, 233, 0.6), inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.rw-list {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1em;
  outline: 0;
  overflow: auto;
  max-height: 200px;
}

.rw-list-option {
  -ms-user-select: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: #333;
  cursor: pointer;
  border: 1px solid transparent;
}

.rw-list-option.rw-state-focus,
.rw-list-option.rw-state-focus:hover {
  background-color: transparent;
  border-color: #66afe9;
  color: #333;
}

.rw-list-option:hover,
.rw-list-option:hover.rw-state-focus {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.rw-list-option.rw-state-selected,
.rw-list-option.rw-state-selected:hover {
  background-color: #337ab7;
  border-color: #337ab7;
  color: white;
}

fieldset[disabled] .rw-list-option,
.rw-list-option.rw-state-disabled,
.rw-list-option.rw-state-readonly {
  box-shadow: none;
  cursor: not-allowed;
  color: #999;
  opacity: .7;
}

fieldset[disabled] .rw-list-option:hover,
.rw-list-option.rw-state-disabled:hover,
.rw-list-option.rw-state-readonly:hover {
  background: none;
  border-color: transparent;
}

.rw-list-empty,
.rw-list-option,
.rw-list-optgroup {
  padding: .143em .75em;
  outline: 0;
}

.rw-list-optgroup {
  font-weight: bold;
  padding-top: 7px;
}

.rw-list-option-create {
  border-top: 1px #ccc solid;
}

.rw-dropdown-list-autofill {
  padding: 0;
}

.rw-dropdown-list-input {
  background-color: transparent;
  vertical-align: middle;
  padding-right: 0;
  max-width: 1px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rw-rtl .rw-dropdown-list-input {
  padding-right: .857em;
  padding-left: 0;
}

.rw-filter-input {
  position: relative;
  margin: 4px;
  padding-right: 0;
}

.rw-filter-input .rw-rtl {
  padding-right: .857em;
  padding-left: 0;
}

.rw-filter-input .rw-select,
.rw-filter-input .rw-btn {
  opacity: .75;
  cursor: text;
}

.rw-filter-input>.rw-select,
.rw-filter-input>.rw-select:active,
.rw-filter-input>.rw-select:hover {
  background: none;
  cursor: auto;
  cursor: initial;
  box-shadow: none;
}

.rw-number-picker .rw-btn {
  cursor: pointer;
  height: calc(1.2145em - 1px);
  margin-top: -1px\9;
  height: 1.2145em\9;
  line-height: 1.2145em;
  line-height: calc(1.2145em - 1px);
  display: block;
  border: none;
}

.rw-number-picker .rw-btn:hover,
.rw-number-picker .rw-btn:active {
  background-color: #e6e6e6;
}

.rw-number-picker .rw-btn:active {
  box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
}

.rw-state-disabled .rw-number-picker .rw-btn,
.rw-state-readonly .rw-number-picker .rw-btn,
fieldset[disabled] .rw-number-picker .rw-btn,
.rw-state-disabled .rw-number-picker .rw-btn:hover,
.rw-state-readonly .rw-number-picker .rw-btn:hover,
fieldset[disabled] .rw-number-picker .rw-btn:hover,
.rw-state-disabled .rw-number-picker .rw-btn:active,
.rw-state-readonly .rw-number-picker .rw-btn:active,
fieldset[disabled] .rw-number-picker .rw-btn:active {
  cursor: not-allowed;
  background-color: inherit;
  background-image: none;
  box-shadow: none;
}

.rw-number-picker .rw-select {
  vertical-align: middle;
}

.rw-number-picker .rw-select,
.rw-number-picker .rw-select:hover,
.rw-number-picker .rw-select:active {
  box-shadow: none;
}

.rw-calendar-popup {
  right: auto;
  min-width: 0;
  width: 18em;
}

.rw-calendar {
  border-radius: 4px;
  background-color: #fff;
  border: #ccc 1px solid;
  overflow: hidden;
}

.rw-calendar.rw-popup {
  border-color: #ccc;
}

.rw-calendar-now {
  font-weight: bold;
}

.rw-calendar-btn-left,
.rw-calendar-btn-right {
  width: 12.5%;
}

.rw-calendar-btn-view {
  width: 75%;
}

.rw-calendar-footer {
  border-top: 1px solid #ccc;
}

.rw-calendar-grid {
  outline: none;
  height: 14.28571429em;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  background-color: #fff;
}

.rw-head-cell {
  text-align: center;
  border-bottom: 1px solid #ccc;
  padding: .25em;
}

.rw-cell {
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  line-height: normal;
  text-align: center;
  border: 1px solid transparent;
  padding: .25em;
}

.rw-cell:hover {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #333;
}

.rw-cell.rw-state-focus,
.rw-cell.rw-state-focus:hover {
  background-color: transparent;
  border-color: #66afe9;
  color: #333;
}

.rw-cell.rw-state-selected,
.rw-cell.rw-state-selected:hover {
  background-color: #337ab7;
  border-color: #337ab7;
  color: white;
}

.rw-cell.rw-state-disabled {
  color: #999;
  opacity: .7;
}

.rw-cell.rw-state-disabled:hover {
  background: none;
  border-color: transparent;
}

.rw-calendar-month .rw-cell {
  text-align: center;
}

.rw-cell-off-range {
  color: #999;
}

.rw-calendar-transition-group {
  position: relative;
}

.rw-calendar-transition {
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  overflow: hidden;
}

.rw-calendar-transition-top {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.rw-calendar-transition-bottom {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.rw-calendar-transition-right {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.rw-calendar-transition-left {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.rw-calendar-transition-entering.rw-calendar-transition-top,
.rw-calendar-transition-entered.rw-calendar-transition-top,
.rw-calendar-transition-entering.rw-calendar-transition-bottom,
.rw-calendar-transition-entered.rw-calendar-transition-bottom {
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.rw-calendar-transition-entering.rw-calendar-transition-right,
.rw-calendar-transition-entered.rw-calendar-transition-right,
.rw-calendar-transition-entering.rw-calendar-transition-left,
.rw-calendar-transition-entered.rw-calendar-transition-left {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.rw-calendar-transition-exiting.rw-calendar-transition-top {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.rw-calendar-transition-exiting.rw-calendar-transition-bottom {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.rw-calendar-transition-exiting.rw-calendar-transition-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.rw-calendar-transition-exiting.rw-calendar-transition-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.rw-select-list {
  overflow: auto;
  position: relative;
}

.rw-select-list .rw-list {
  max-height: none;
  font-size: 1em;
}

.rw-select-list-label {
  display: block;
  position: relative;
  font-weight: normal;
  cursor: inherit;
  padding-left: 20px;
  margin: 0;
}

.rw-rtl .rw-select-list-label {
  padding-left: 0;
  padding-right: 20px;
}

input.rw-select-list-input {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 0.1em /9;
  margin: 0;
  line-height: normal;
  cursor: inherit;
}

.rw-rtl input.rw-select-list-input {
  left: auto;
  right: 0;
}

.rw-loading-mask {
  content: '';
  background: url(/static/media/loader-big.a770b679.gif) no-repeat center;
  position: absolute;
  background-color: #fff;
  border-radius: 4px;
  opacity: .7;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.rw-multiselect {
  cursor: text;
}

.rw-multiselect .rw-input-reset {
  height: calc(2.429em - 2px);
  margin-top: -2px\9;
  height: 2.429em\9;
  border-width: 0;
  width: auto;
  max-width: 100%;
  padding: 0 .857em;
}

.rw-multiselect .rw-select {
  vertical-align: middle;
}

.rw-multiselect .rw-select,
.rw-multiselect .rw-select:hover,
.rw-multiselect .rw-select:active {
  box-shadow: none;
  background: none;
}

.rw-multiselect-taglist {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  vertical-align: 0;
  outline: none;
}

.rw-multiselect-tag {
  display: inline-table;
  color: inherit;
  padding: 0 .35em 0 .35em;
  margin-left: calc(0.279335em - 1px);
  margin-top: .279335em;
  margin-top: calc(0.279335em - 1px);
  height: 1.87033em;
  border-radius: 3px;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: default;
  vertical-align: top;
  text-align: center;
  overflow: hidden;
  max-width: 100%;
}

.rw-multiselect-tag>* {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}

.rw-rtl .rw-multiselect-tag {
  margin-left: 0;
  margin-right: calc(0.279335em - 1px);
  padding: 0 .35em 0 .35em;
}

.rw-multiselect-tag.rw-state-focus,
.rw-multiselect-tag.rw-state-focus:hover {
  background-color: transparent;
  border-color: #66afe9;
  color: #333;
}

.rw-multiselect-tag.rw-state-readonly,
.rw-multiselect-tag.rw-state-disabled,
.rw-state-readonly .rw-multiselect-tag,
.rw-state-disabled .rw-multiselect-tag,
fieldset[disabled] .rw-multiselect-tag {
  cursor: not-allowed;
}

.rw-multiselect-tag.rw-state-disabled,
.rw-state-disabled .rw-multiselect-tag,
fieldset[disabled] .rw-multiselect-tag {
  opacity: .65;
}

fieldset[disabled] .rw-multiselect-tag {
  box-shadow: none;
  cursor: not-allowed;
}

.rw-multiselect-tag-btn {
  color: inherit;
  margin-left: .25em;
}

.rw-rtl .rw-multiselect-tag-btn {
  margin-left: 0;
  margin-right: .25em;
}

.rw-autocomplete .rw-select {
  position: absolute;
  display: block;
  width: auto;
  top: 0;
  bottom: 0;
  right: 0;
}

.rw-popup-container {
  position: absolute;
  z-index: 1005;
  top: 100%;
  left: -6px;
  right: -6px;
}

.rw-popup-container.rw-dropup {
  top: auto;
  bottom: 100%;
}

.rw-state-focus .rw-popup-container {
  z-index: 1006;
}

.rw-popup-transition {
  width: 100%;
  margin-bottom: 6px;
  padding: 0 6px;
}

.rw-dropup>.rw-popup-transition {
  margin-bottom: 0;
  margin-top: 6px;
}

.rw-popup {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  box-shadow: 0 5px 6px rgba(0,0,0,0.2);
  border: #ccc 1px solid;
  background: #fff;
}

.rw-dropup .rw-popup {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  box-shadow: 0 -2px 6px rgba(0,0,0,0.2);
}

.rw-popup-transition {
  -webkit-transition: -webkit-transform 200ms;
  transition: -webkit-transform 200ms;
  transition: transform 200ms;
  transition: transform 200ms, -webkit-transform 200ms;
}

.rw-popup-transition-entering {
  overflow: hidden;
}

.rw-popup-transition-entering .rw-popup-transition {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

.rw-popup-transition-exiting .rw-popup-transition {
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}

.rw-popup-transition-exiting,
.rw-popup-transition-exited {
  overflow: hidden;
}

.rw-popup-transition-exiting .rw-popup-transition,
.rw-popup-transition-exited .rw-popup-transition {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.rw-popup-transition-exiting.rw-dropup .rw-popup-transition,
.rw-popup-transition-exited.rw-dropup .rw-popup-transition {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.rw-popup-transition-exited {
  display: none;
}

.rw-state-disabled {
  box-shadow: none;
  cursor: not-allowed;
}
